<script>

export default {
  components: {},
  mounted() {
    this.callBack()
  },
  methods: {
    callBack() {
      this.$auth.handleRedirectCallback()
      this.$router.push('/')
    },

  },
}
</script>
